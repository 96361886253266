import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import { PORTFOLIO } from "../shared/portfolio";
import { Close } from "@material-ui/icons";
import "./PortfolioComponent.scss";
import useMediaQuery from "@material-ui/core/useMediaQuery";

function Portfolio() {
  const [selected, setSelected] = useState(null);
  const [modal, setModal] = useState(false);
  const mobile = useMediaQuery("(max-width: 720px)");

  const clickHandler = (index) => {
    setSelected(index);
    setModal(!modal);
  };

  const projects = PORTFOLIO.map((project, index) => {
    return (
      <div
        className="project--container"
        key={index}
        onClick={() => clickHandler(index)}
      >
        <div
          className="project"
          style={{ backgroundImage: `url(${project.thumbnail})` }}
        />
        <div className="project--info">
          <div className="title">{project.title}</div>
          <div className="year">{project.year}</div>
        </div>
      </div>
    );
  });

  const projectContent = () => {
    const images = PORTFOLIO[selected].image.map((image, index) => {
      return (
        <img
          className="image"
          src={image}
          alt={`${PORTFOLIO[selected].title}.${index}`}
          key={index}
        />
      );
    });
    return (
      <>
        {images}
        <div className="modal--caption">
          <div className="project--main">
            <div className="project--title">{PORTFOLIO[selected].title}</div>
            <div className="project--year">{PORTFOLIO[selected].year}</div>
          </div>
          <div className="project--description">
            {PORTFOLIO[selected].description}
          </div>
        </div>
      </>
    );
  };

  return (
    <>
      <div className="portfolio--container">{projects}</div>
      <Modal
        transparent={true}
        className="modal"
        show={modal}
        onHide={() => clickHandler(null)}
        animation={mobile ? false : true}
      >
        <Modal.Body className="modal--body">
          {selected !== null && projectContent()}
          <Close className="close" onClick={() => clickHandler(null)} />
        </Modal.Body>
      </Modal>
    </>
  );
}

export default Portfolio;
