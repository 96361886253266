export const BIO = [
  {
    title: "Perfil",
    content:
      "Estudiantes del último año de la Carrera de Arquitectura en la Universidad Nacional de La Matanza. Creatividad, poder resolutivo, trabajo en equipo y liderazgo .",
  },
  {
    title: "Proyectos",
    content:
      "Se presentan algunos de los proyectos de carácter universitario realizados, tanto de manera individual como grupal, a lo largo de la carrera.",
  },
  {
    title: "Objetivo",
    content:
      "AXIS Arquitectura fue creada como marca de un equipo y testigo de su evolución académica y profesional. La calidad y el aprendizaje continuo son los pilares de este proyecto.",
  },
];
