export const PORTFOLIO = [
  {
    image: [
      "/assets/images/portfolio/barrio_santos_vega.jpg",
      "/assets/images/portfolio/barrio_santos_vega2.jpg",
    ],
    title: "Viviendas Colectivas",
    year: "2021",
    description:
      "Diseño de viviendas colectivas para la reinserción de la comunidad del Barrio Santos Vega.",
    thumbnail: "/assets/images/portfolio/barrio_santos_vega.jpg",
    home: "/assets/images/hero/barrio_santos_vega.jpg",
  },
  {
    image: [
      "/assets/images/portfolio/escuela.jpg",
      "/assets/images/portfolio/escuela2.jpg",
      "/assets/images/portfolio/escuela3.jpg",
    ],
    title: "Escuela tecnica",
    year: "2021",
    description: "Diseño de Escuela Técnica para el Barrio Santos Vega.",
    thumbnail: "/assets/images/portfolio/escuela.jpg",
    home: "/assets/images/hero/escuela.jpg",
  },
  {
    image: ["/assets/images/portfolio/20dejunio.jpg"],
    title: "Barrio 20 de Junio",
    year: "2019",
    description:
      "Diseño de espacio para el desarrollo de actividades comunitarias para el Barrio 20 de Junio.",
    thumbnail: "/assets/images/portfolio/20dejunio.jpg",
    home: null,
  },
  {
    image: [
      "/assets/images/portfolio/bodega2.jpg",
      "/assets/images/portfolio/bodega.jpg",
    ],
    title: "Bodega",
    year: "2020",
    description:
      "Diseño de Bodega y espacio de producción general en el terreno de Mendoza.",
    thumbnail: "/assets/images/portfolio/bodega2.jpg",
    home: null,
  },
  {
    image: [
      "/assets/images/portfolio/forbidden_city.jpg",
      "/assets/images/portfolio/forbidden_city2.jpg",
    ],
    title: "Microciudad Compacta",
    year: "2020",
    description:
      "Diseño y transformación morfológica según reglas de conformación a partir del referente arquitectónico Forbidden City.",
    thumbnail: "/assets/images/portfolio/forbidden_city.jpg",
    home: null,
  },
  {
    image: [
      "/assets/images/portfolio/palace.jpg",
      "/assets/images/portfolio/palace2.jpg",
    ],
    title: "War and Destruction Palace",
    year: "2020",
    description:
      "Diseño y transformación a partir del referente de Arquitectura Potemkin Palace of Soviets.",
    thumbnail: "/assets/images/portfolio/palace.jpg",
    home: "/assets/images/hero/palace.jpg",
  },
  {
    image: ["/assets/images/portfolio/viviendas_medio_camino.jpg"],
    title: "Viviendas de Medio Camino",
    year: "2020",
    description:
      "Diseño de espacio y reinserción social para mujeres víctimas de violencia.",
    thumbnail: "/assets/images/portfolio/viviendas_medio_camino.jpg",
    home: "/assets/images/hero/viviendas_medio_camino.jpg",
  },
  {
    image: ["/assets/images/portfolio/viviendas_colectivas.jpg"],
    title: "Vivienda Colectiva",
    year: "2019",
    description: "Diseño de viviendas colectivas sobre espacio preexistente.",
    thumbnail: "/assets/images/portfolio/viviendas_colectivas.jpg",
    home: null,
  },
];
