import React, { useEffect, useRef, useState } from "react";
import "./VerticalSelectorComponent.scss";
import { SECTIONS } from "../../shared/sections";

export default function VerticalSelector(props) {
  const [selectedItem, setSelectedItem] = useState(0);
  const [focusedItem, setFocusedItem] = useState(0);
  const clicked = useRef(0);

  const linesLength = {
    top: ["line--to--top short", "line--to--top", "line--to--top long"],
    bottom: [
      "line--to--bottom long",
      "line--to--bottom",
      "line--to--bottom short",
    ],
  };

  useEffect(() => {
    if (clicked.current && props.selected !== selectedItem) return;
    setFocusedItem(props.selected);
    setSelectedItem(props.selected);
    clicked.current = false;
  }, [props.selected, selectedItem]);

  const items = SECTIONS.map((section, index) => {
    return (
      <div
        className={selectedItem === index ? "--active" : ""}
        key={index}
        onMouseEnter={() => setFocusedItem(index)}
        onMouseLeave={() => setFocusedItem(selectedItem)}
        onClick={() => {
          props.clickHandler(index);
          setSelectedItem(index);
          clicked.current = true;
        }}
      >
        {section}
      </div>
    );
  });

  return (
    <>
      <div className="line--wrapper">
        <div className={linesLength.top[focusedItem]} />
        <div
          className={
            focusedItem === selectedItem ? "menu--icon big" : "menu--icon"
          }
        />
        <div className={linesLength.bottom[focusedItem]} />
      </div>
      <div className="text--wrapper">{items}</div>
    </>
  );
}
