import React from "react";
import { BIO } from "../shared/bio";
import "./BioComponent.scss";

const Bio = () => {
  return (
    <div className="bio--body">
      <div className="text--left text--1">
        <h4>{BIO[0].title}</h4>
        <p>{BIO[0].content}</p>
      </div>
      <div className="line--vertical line--bio"></div>
      <div className="text--right text--2">
        <h4>{BIO[1].title}</h4>
        <p>{BIO[1].content}</p>
      </div>
      <div className="text--left text--3">
        <h4>{BIO[2].title}</h4>
        <p>{BIO[2].content}</p>
      </div>
    </div>
  );
};

export default Bio;
