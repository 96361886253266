import React, { useEffect, useMemo, useRef, useState } from "react";
import "./HomeComponent.scss";
import Menu from "./menu/MenuComponent";
import Hero from "./HeroComponent";
import Bio from "./BioComponent";
import OpacityOnScroll from "./OpacityOnScroll";
import { SECTIONS } from "../shared/sections";
import { useMediaQuery } from "@material-ui/core";
import Portfolio from "./PortfolioComponent";
import Footer from "./FooterComponent";

const colors = ["white", "white", "#b98159"];

function Home() {
  const [darkTopMenu, setDarkTopMenu] = useState(false);
  const [selectedSection, setSelectedSection] = useState(0);
  const refs = useMemo(() => [], []);
  refs.push(useRef());
  refs.push(useRef());
  refs.push(useRef());
  const mobile = useMediaQuery("(max-width: 720px)");

  const scrollToRef = (index) => {
    window.scrollTo({
      top: refs[index].current,
      behavior: "smooth",
    });
  };

  const changeMenu = (value) => {
    if (!mobile) setDarkTopMenu(value);
  };

  useEffect(() => {
    const scrollHandler = () => {
      const scrollPosition = window.pageYOffset;
      if (scrollPosition < 0.7 * refs[1].current && selectedSection !== 0) {
        setSelectedSection(0);
      } else if (
        scrollPosition > 0.7 * refs[1].current &&
        scrollPosition < 0.9 * refs[2].current &&
        selectedSection !== 1
      ) {
        setSelectedSection(1);
      } else if (
        scrollPosition > 0.9 * refs[2].current &&
        selectedSection !== 2
      ) {
        setSelectedSection(2);
      }
    };
    window.addEventListener("scroll", scrollHandler);
    return () => {
      window.removeEventListener("scroll", scrollHandler);
    };
  }, [refs, selectedSection]);

  return (
    <>
      <div
        className="background"
        style={{ backgroundColor: colors[selectedSection] }}
      />
      <div
        onMouseEnter={() => changeMenu(true)}
        onMouseLeave={() => changeMenu(false)}
      >
        <Menu
          scroll={scrollToRef}
          darkMode={selectedSection === 0 ? darkTopMenu : true}
          selectedSection={selectedSection}
        />
      </div>
      <OpacityOnScroll fadeOut sticky ref={refs[0]}>
        <Hero />
      </OpacityOnScroll>
      <OpacityOnScroll fadeIn fadeOut sticky ref={refs[1]}>
        <div className="section">
          <div className="section--title">
            <p>{SECTIONS[1]}</p>
          </div>
          <Portfolio />
        </div>
      </OpacityOnScroll>
      <OpacityOnScroll fadeIn sticky ref={refs[2]}>
        <div className="section">
          <div className="section--title bio--title">
            <p>{SECTIONS[2]}</p>
          </div>
          <Bio />
        </div>
      </OpacityOnScroll>
      <Footer />
    </>
  );
}

export default Home;
