import React from "react";
import "./ContactoComponent.scss";
import { CONTACTO } from "../../shared/contact";
import { Email, Instagram, Phone } from "@material-ui/icons";

const Contacto = (props) => {
  return (
    <div className="icon--wrapper">
      <a
        href={`mailto:${CONTACTO.email}`}
        style={{ textDecoration: "none" }}
        target="_blank"
        rel="noreferrer"
      >
        <Email
          className={
            props.darkMode ? "social--icon light" : "social--icon dark"
          }
        />
      </a>
      <a
        href={CONTACTO.instagram}
        style={{ textDecoration: "none" }}
        target="_blank"
        rel="noreferrer"
      >
        <Instagram
          className={
            props.darkMode ? "social--icon light" : "social--icon dark"
          }
        />
      </a>
      <a
        href={`callto:${CONTACTO.telefono}`}
        style={{ textDecoration: "none" }}
        target="_blank"
        rel="noreferrer"
      >
        <Phone
          className={
            props.darkMode ? "social--icon light" : "social--icon dark"
          }
        />
      </a>
    </div>
  );
};

export default Contacto;
