import React, { useState } from "react";
import { Carousel } from "react-bootstrap";
import { PORTFOLIO } from "../shared/portfolio";
import { AddCircleOutline } from "@material-ui/icons";
import "./HeroComponent.scss";

function Hero() {
  const [caption, setCaption] = useState(true);

  const toggleCaption = () => {
    setCaption(!caption);
  };

  const projects = PORTFOLIO.map((project, index) => {
    return (
      project.home && (
        <Carousel.Item className="carousel--item" key={index}>
          <div
            className="carousel--img"
            style={{ backgroundImage: `url(${project.home})` }}
          />
          <Carousel.Caption
            className={
              !caption ? "carousel--caption" : "carousel--caption closed"
            }
          >
            <AddCircleOutline
              className={!caption ? "plus open" : "plus closed"}
              onClick={toggleCaption}
            />
            <div
              className={!caption ? "info--wrapper" : "info--wrapper closed"}
            >
              <div className="project--main">
                <div className="project--title">{project.title}</div>
                <div className="project--year">{project.year}</div>
              </div>
              <div className={"project--description "}>
                {project.description}
              </div>
            </div>
          </Carousel.Caption>
        </Carousel.Item>
      )
    );
  });

  return (
    <div className="carousel--wrapper">
      <Carousel
        fade
        nextIcon={false}
        prevIcon={false}
        controls={false}
        interval={3000}
      >
        {projects}
      </Carousel>
      <div
        className={
          !caption
            ? "lines--wrapper sticky-top"
            : "lines--wrapper sticky-top closed"
        }
      >
        <div className="line--to--left" />
        <div className="line--to--right" />
      </div>
    </div>
  );
}

export default Hero;
