import React, { useState } from "react";
import "./MenuComponent.scss";
import { Squash as Hamburger } from "hamburger-react";
import VerticalSelector from "./VerticalSelectorComponent";
import HorizontalSelector from "./HorizontalSelectorComponent";
import Contacto from "./ContactoComponent";
import { useMediaQuery } from "@material-ui/core";

function Menu(props) {
  const [isOpen, setOpen] = useState(false);
  const mobile = useMediaQuery("(max-width: 720px)");

  const clickHandler = (index) => {
    props.scroll(index);
  };

  return (
    <>
      <div
        className={
          props.darkMode ? "menu--background dark" : "menu--background"
        }
      >
        <div className="menu--button">
          <Hamburger size={27} toggled={isOpen} toggle={setOpen} />
        </div>
        <div className="logo--wrapper">
          <div className={props.darkMode ? "logo--main" : "logo--main dark"}>
            AXIS
          </div>
          <div className={props.darkMode ? "menu--icon" : "menu--icon dark"} />
          <div className="logo--sub">Arquitectura</div>
        </div>
        <HorizontalSelector
          clickHandler={clickHandler}
          dark={props.darkMode}
          selected={props.selectedSection}
        />
        {!mobile && <Contacto darkMode={props.darkMode} />}
      </div>
      {/* ----- Side Bar ----- */}
      <div
        className={isOpen ? "transparent--box --visible" : "transparent--box"}
        onClick={() => setOpen(false)}
      />
      <div className={isOpen ? "dark--box --visible " : "dark--box"}>
        <VerticalSelector
          clickHandler={clickHandler}
          selected={props.selectedSection}
        />
        <Contacto darkMode={props.darkMode} />
      </div>
    </>
  );
}

export default Menu;
