import React, { useEffect, useRef, useState } from "react";
import { PropTypes } from "prop-types";
import "./OpacityOnScroll.scss";
import useMediaQuery from "@material-ui/core/useMediaQuery";

const gap = 10;

const OpacityOnScroll = React.forwardRef(
  ({ startAt, endAt, fadeIn, fadeOut, sticky, zIndex, children }, ref) => {
    const [opacity, setOpacity] = useState(fadeIn ? 0 : 1);
    const prevPosition = useRef();
    const wrapperRef = useRef();
    const enterPosition = useRef();
    const viewPosition = useRef();
    const exitPosition = useRef();
    const stickyPosition = useRef();
    const mobile = useMediaQuery("(max-width: 720px)");

    window.onbeforeunload = () => {
      window.scrollTo(0, 0);
    };
    window.onresize = () => {
      if (!mobile) window.scrollTo(0, 0);
    };

    useEffect(() => {
      const setValues = () => {
        enterPosition.current =
          wrapperRef.current.offsetTop - (1 - startAt) * window.innerHeight;
        viewPosition.current = wrapperRef.current.offsetTop;
        exitPosition.current =
          wrapperRef.current.offsetTop +
          endAt * wrapperRef.current.clientHeight;
        stickyPosition.current =
          window.innerHeight - wrapperRef.current.clientHeight;
        ref.current = viewPosition.current;
      };
      setValues();
      window.addEventListener("resize", setValues);
      return () => {
        window.removeEventListener("resize", setValues);
      };
    }, [wrapperRef, ref, startAt, endAt]);

    useEffect(() => {
      const handleScroll = () => {
        const scrollPosition = Math.round(window.pageYOffset / gap) * gap;
        const fullPosition = viewPosition.current - stickyPosition.current;
        if (prevPosition.current === scrollPosition) return;
        prevPosition.current = scrollPosition;
        if (fadeIn && scrollPosition < enterPosition.current) {
          setOpacity(0);
        } else if (fadeOut && scrollPosition > exitPosition.current) {
          setOpacity(0);
        } else if (
          fadeIn &&
          scrollPosition >= enterPosition.current &&
          scrollPosition <= viewPosition.current
        ) {
          setOpacity(
            (scrollPosition - enterPosition.current) /
              (viewPosition.current - enterPosition.current)
          );
        } else if (
          fadeOut &&
          scrollPosition >= fullPosition &&
          scrollPosition <= exitPosition.current
        ) {
          setOpacity(
            1 -
              (scrollPosition - fullPosition) /
                (exitPosition.current - fullPosition)
          );
        }
      };
      prevPosition.current = window.pageYOffset;
      window.addEventListener("scroll", handleScroll);
      return () => {
        window.removeEventListener("scroll", handleScroll);
      };
    }, [fadeIn, fadeOut]);

    return sticky && !mobile ? (
      <div
        className="sticky--wrapper"
        style={{ top: stickyPosition.current }}
        ref={wrapperRef}
      >
        <div
          className="opacity--wrapper"
          style={{ opacity: opacity, zIndex: opacity > 0.5 ? zIndex : -1 }}
        >
          {children}
        </div>
      </div>
    ) : (
      <div
        className="opacity--wrapper"
        ref={wrapperRef}
        style={{
          opacity: mobile ? 1 : opacity,
          zIndex: opacity > 0.5 ? zIndex : -1,
        }}
      >
        {children}
      </div>
    );
  }
);

OpacityOnScroll.propTypes = {
  startAt: PropTypes.number,
  endAt: PropTypes.number,
  fadeIn: PropTypes.bool,
  fadeOut: PropTypes.bool,
  sticky: PropTypes.bool,
  zIndex: PropTypes.number,
  children: PropTypes.node.isRequired,
};

OpacityOnScroll.defaultProps = {
  startAt: 0,
  endAt: 1,
  fadeIn: false,
  fadeOut: false,
  sticky: true,
  zIndex: 0,
};

export default OpacityOnScroll;
