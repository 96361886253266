import React, { useEffect, useRef, useState } from "react";
import "./HorizontalSelectorComponent.scss";
import { SECTIONS } from "../../shared/sections";

export default function HorizontalSelector(props) {
  const [selectedItem, setSelectedItem] = useState(0);
  const [focusedItem, setFocusedItem] = useState(0);
  const clicked = useRef(0);

  const linePosition = [
    "top--menu--line first",
    "top--menu--line second",
    "top--menu--line third",
  ];

  useEffect(() => {
    if (clicked.current && props.selected !== selectedItem) return;
    setFocusedItem(props.selected);
    setSelectedItem(props.selected);
    clicked.current = false;
  }, [props.selected, selectedItem]);

  const items = SECTIONS.map((section, index) => {
    return (
      <div
        className={selectedItem === index ? "--active" : ""}
        key={index}
        onMouseEnter={() => setFocusedItem(index)}
        onMouseLeave={() => setFocusedItem(selectedItem)}
        onClick={() => {
          props.clickHandler(index);
          setSelectedItem(index);
          clicked.current = true;
        }}
      >
        {section}
      </div>
    );
  });

  return (
    <>
      <div
        className={
          props.dark
            ? "menu--items--horizontal dark"
            : "menu--items--horizontal"
        }
      >
        {items}
      </div>
      <div className="topLine--wrapper">
        <div className={linePosition[focusedItem]}>
          <span
            className={
              props.dark
                ? "line--transition--helper dark"
                : "line--transition--helper"
            }
          ></span>
        </div>
      </div>
    </>
  );
}
